.videoContainer {
  position: relative;
}

.videoPreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  color: white;
}
